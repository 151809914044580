(function() {
    'use strict';

    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false;

    let promociones = [];
    let heros = [];

    // let idxEq = 0;

    document.addEventListener('DOMContentLoaded', function(){
        iniciarClicks();
    });

    function iniciarClicks() {
            
        document.addEventListener('click', (e) => {
            e.preventDefault();
            clicksWeb(e.target);
        });

        // window.addEventListener('scroll', function() {
        //     navBackground();
        // })
        // navBackground();


        if (document.getElementById("fotos_fondo")) {
            heros = document.querySelectorAll('.foto')
            setInterval(next_fondo, 2500); 
        }
        
    }


    // ! FUNCIÓN PARA ACTIVAR EL VIDEO Y REPRODUCIRLO AL ESTAR LA PANTALLA ACTIVA
    function toggleVideo() {
        const trailer = document.querySelector('.videoPopup');
        const video = document.querySelector('video');

        trailer.classList.toggle('active');
        video.currentTime = 0;
        if (trailer.classList.contains('active')) {
            video.play();            
        } else {
            video.pause();
        };
    }    


    // ! FUNCIÓN PARA ACTIVAR TODOS LOS CLICS DE LA PÁGINA
    function clicksWeb(objetoDom) {

        // console.log(objetoDom);

        if ( objetoDom.matches('.opCard') || objetoDom.matches('.clCard')) {
            rotarCard(objetoDom);
        }

        if ( objetoDom.matches('.btnFaq') || objetoDom.matches('.accordion-title') || objetoDom.matches('.iconFaq') ) {
            toggleAccordion(objetoDom);            
        }

        // ? BOTÓN PARA REPRODUCIR EL VÍDEO PROMOCIONAL
        if ( objetoDom.matches('#play') || objetoDom.matches('#close') || objetoDom.matches('#imgPlay') ) {
            toggleVideo();
        }

        // ? BOTÓN LATERAL PARA SUBIR AL INICIO DE LA PANTALLA
        if ( objetoDom.matches('#botInicio') ) {

            window.scrollTo({top: 0, behavior: 'smooth'});

            // Este código es con JQUERY
            // $('html, body').animate({
            //    scrollTop: 0
            // }, 100);        
        }

        // ? LINK A PAGINA DE INICIO
        if ( objetoDom.matches('.mnuInicio') || objetoDom.matches('#logoBDK')) {
            let url = 'index.html';
            window.open(url,'_self');
        }            

        // ? LINK A CONTENIDO
        if ( objetoDom.matches('.mnuContenido')) {
            scroll_to('sec_contenido');
        }            

        // ? LINK A GALERÍA
        if ( objetoDom.matches('.mnuGaleria')) {
            scroll_to('sec_galeria');
        }            

        // ? LINK A PRECIOS
        if ( objetoDom.matches('.mnuPrecios')) {
            scroll_to('sec_precios');
        }            

        // ? LINK A AGENDA
        if ( objetoDom.matches('.mnuAgenda')) {
            scroll_to('sec_agenda');
        }            

        // ? LINK A CONTACTO
        if ( objetoDom.matches('.mnuContacto')) {
            scroll_to('footer');
        }            

        // ? LINK A PAGINA DE BDKSISTEMAS
        if ( objetoDom.matches('.enlaceBDK')) {
            let url = 'https://www.bdksistemas.com.mx/';
            window.open(url);
        }            

        // ? BOTÓN PARA CERRAR LOS POPUP INFORMATIVOS
        if (objetoDom.matches('#close')) {
            const popup = document.querySelector('.infoPopup');
            popup.classList.toggle('active');            
        }

        // ? LINK A WHATSAPP DE VENTAS
        if ( objetoDom.matches('.botWP')) {
            let url = 'https://api.whatsapp.com/send?phone=528118222666&text=Hola,%20buen%20día,%20%20quiero%20pedir%20informes%20sobre%20la%20renta%20del%20departamento%20en%20Playa%20Del%20Carmen';
            window.open(url, '_blank');
        } 


        // ? LINKS A FACEBOOK
        if ( objetoDom.matches('.botFB')) {               
            let url = 'https://www.facebook.com/departamentoenlaplaya';
            window.open(url, '_blank');
        } 

        // ? LINKS A ISNTAGRAM
        if ( objetoDom.matches('.botInsta')) {
            console.log('Si recibo el click de Insta');                
            let url = 'https://www.instagram.com/departamentoenplayadelcarmen';
            window.open(url, '_blank');
        } 

        // ? LINKS A YOUTUBE
        if ( objetoDom.matches('.botYT')) {
            let url = 'https://www.youtube.com/bdksistemas';
            window.open(url, '_blank');
        } 


        // ? BOTÓN REGRESAR
        if ( objetoDom.matches('#regresar')) { 
            window.history.back();
        } 

        // ? ICONO DEL MENÚ HAMBURGUESA
        if ( objetoDom.matches('#botApp')) { 
            
            menuLat = true;
            apertura();
        }

    }


    // ! FUNCIÓN PARA ABRIR EL MENÚ CUANDO EL TAMAÑO SEA TABLET O MÓVIL
    function apertura() {
        let menu    = document.getElementById('navPrincipal');
        let botSubir = document.getElementById('botInicio');
        let botAccWP	 = document.getElementById('botWP');
        
        if (cerrado) {
            menu.style.width = '100vw!important';
            cerrado = false;
            
            if (botSubir) {
                botSubir.style.visibility = 'hidden';
                botAccWP.style.visibility = 'hidden';
            }

        } else {
            menu.style.width = '0%';
            menu.style.overflow = 'hidden';
            cerrado = true;
            menuLat = false;
            if (botSubir) {
                botSubir.style.visibility = 'visible';
                botAccWP.style.visibility = 'visible';
            }
        }
        menu.classList.toggle('menu2');
    };   


    
    function navBackground() {

        let nav = document.querySelector('.nav');
        let desp_actual = window.pageYOffset;
        let ancho_screen = window.innerWidth;        

        nav.style.borderBottom = ''; 
        nav.style.background = 'linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%)';
        nav.style.transition = '.5s';
    }

    function next_fondo() {
        let idxHero = actBanner(heros, 0);      
        nextBanner(heros, idxHero, '+');        
    }

    function next_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '+');        
    }

    function back_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '-');
    }

    function actBanner(array, idxBan) {
        while (idxBan < array.length) {
            if (array[idxBan].classList.contains('foto_visible')) {
                break;
            }
            idxBan++;
        }
        return idxBan;
    }

    function nextBanner(array, idx, opr) {
        let idxNext = idx;
        if (opr==='+') {
            idxNext++;
            if (idxNext > array.length - 1) {
                idxNext = 0;
            }    
        } else {
            --idxNext;
            if (idxNext < 0) {
                idxNext = array.length - 1;
            }
        }
        array[idx].classList.remove('foto_visible');
        array[idxNext].classList.add('foto_visible');
    }

    // ! FUNCIÓN PARA MOVERSE A UNA SECCIÓN DE LA PÁGINA
    function scroll_to(id_elemento) {
        let ancho_screen = window.innerWidth;        
        if (ancho_screen <= 768 && cerrado===false) {
            apertura();
        }
        location.hash = '#' + id_elemento;
    }    

    
})();